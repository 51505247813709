import { render, staticRenderFns } from "./StaffList.vue?vue&type=template&id=c8055bf0&"
import script from "@/scripts/administrator/staffList.js?vue&type=script&lang=js&"
export * from "@/scripts/administrator/staffList.js?vue&type=script&lang=js&"
import style0 from "./StaffList.vue?vue&type=style&index=0&id=c8055bf0&prod&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports