import commonAct from '@/commonActionHandle.js';
import baseComponent from "@/scripts/baseComponent";
import lazyLoadComponent from '@/scripts/lazyLoadComponent';
import SkeletonBox from '@/components/SkeletonBox';
import { debounce } from 'vue-debounce';
import { mapState } from "vuex";
export default {
    extends: baseComponent,
    props: {
        cardHeight: {
            type: String,
            default: 'height: calc(100dvh - 7rem);'
        },
    },
    components: {
        staffDetailUsers: lazyLoadComponent({
            componentFactory: () => import("@/views/administrator/staffDetailComponents/StaffDetail_Users"),
            loading: SkeletonBox,
        }),
        staffDetailPaidUsers: lazyLoadComponent({
            componentFactory: () => import("@/views/administrator/staffDetailComponents/PaidUser"),
            loading: SkeletonBox,
        }),
        staffStatistics: lazyLoadComponent({
            componentFactory: () => import("@/views/administrator/staffDetailComponents/staffStatistics"),
            loading: SkeletonBox,
        })
    },
    data() {
        return {
            activeTabName: 'statistics',
            userActive: true,
            elementDetail: null,
        };
    },
    filters: {
    },
    computed: {
        ...mapState({
            userInfo: state => state.auth.user,
            rolesInfo: state => state.auth.roles,
            staffsInfo: state => state.staffs.filter((x) => x.mainDepartment === 'OPERATING_STAFF')
                .sort(function (a, b) { return (b.passwordHash !== null && b.passwordHash !== '' ? 1 : 0) - (a.passwordHash !== null && a.passwordHash !== '' ? 1 : 0) }),
            lowBalanceUser: state => state.lowBalanceUser,
        }),
    },
    created() {

    },
    methods: {
        viewDetailInfo(targetItem) {
            this.$set(this, "elementDetail", targetItem);
            this.$set(this, "activeTabName", 'statistics');
            this.$nextTick(() => {
                $("#detailStaffModalInfo").modal({ backdrop: 'static', keyboard: true, });
            });
        },
        closeDetailStaffInfo() {
            $("#detailStaffModalInfo").modal("hide");
            this.$set(this, "elementDetail", null);
        },
    }
}